export default function CopiedIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_175_3896)">
        <path
          d="M6 23C5.45 23 4.97933 22.8132 4.588 22.4397C4.196 22.0655 4 21.6159 4 21.0909V14.4091V7.97802C4 7.83954 4.11226 7.72727 4.25075 7.72727C4.38865 7.72727 4.50067 7.83862 4.50149 7.97652L4.588 22.4397L18.7621 22.5214C18.8937 22.5221 19 22.629 19 22.7607C19 22.8929 18.8929 23 18.7607 23H6ZM9 20.1818C8.45 20.1818 7.97933 19.995 7.588 19.6215C7.196 19.2473 7 18.7977 7 18.2727V4.90909C7 4.38409 7.196 3.9345 7.588 3.56032C7.97933 3.18677 8.45 3 9 3H16L22 8.72727V18.2727C22 18.7977 21.8043 19.2473 21.413 19.6215C21.021 19.995 20.55 20.1818 20 20.1818H9ZM15 9.68182H20L15 4.90909V9.68182Z"
          fill="#A056FF"
        />
        <path
          d="M4.588 22.4397C4.97933 22.8132 5.45 23 6 23H18.7607C18.8929 23 19 22.8929 19 22.7607V22.7607C19 22.629 18.8937 22.5221 18.7621 22.5214L4.588 22.4397ZM4.588 22.4397C4.196 22.0655 4 21.6159 4 21.0909V14.4091V7.97802C4 7.83954 4.11226 7.72727 4.25075 7.72727V7.72727C4.38865 7.72727 4.50067 7.83862 4.50149 7.97652L4.588 22.4397ZM9 20.1818C8.45 20.1818 7.97933 19.995 7.588 19.6215C7.196 19.2473 7 18.7977 7 18.2727V4.90909C7 4.38409 7.196 3.9345 7.588 3.56032C7.97933 3.18677 8.45 3 9 3H16L22 8.72727V18.2727C22 18.7977 21.8043 19.2473 21.413 19.6215C21.021 19.995 20.55 20.1818 20 20.1818H9ZM15 9.68182H20L15 4.90909V9.68182Z"
          stroke="black"
          strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_175_3896)">
          <path
            d="M5.3 21.3L8.825 17.775L8.125 17.075L5.3 19.9L3.875 18.475L3.175 19.175L5.3 21.3ZM6 24C5.30833 24 4.65833 23.8687 4.05 23.606C3.44167 23.3437 2.9125 22.9875 2.4625 22.5375C2.0125 22.0875 1.65633 21.5583 1.394 20.95C1.13133 20.3417 1 19.6917 1 19C1 18.3083 1.13133 17.6583 1.394 17.05C1.65633 16.4417 2.0125 15.9125 2.4625 15.4625C2.9125 15.0125 3.44167 14.6562 4.05 14.3935C4.65833 14.1312 5.30833 14 6 14C6.69167 14 7.34167 14.1312 7.95 14.3935C8.55833 14.6562 9.0875 15.0125 9.5375 15.4625C9.9875 15.9125 10.3437 16.4417 10.606 17.05C10.8687 17.6583 11 18.3083 11 19C11 19.6917 10.8687 20.3417 10.606 20.95C10.3437 21.5583 9.9875 22.0875 9.5375 22.5375C9.0875 22.9875 8.55833 23.3437 7.95 23.606C7.34167 23.8687 6.69167 24 6 24Z"
            fill="#33C464"
            stroke="black"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_175_3896">
          <rect width="26" height="26" fill="white" />
        </clipPath>
        <clipPath id="clip1_175_3896">
          <rect width="12" height="12" fill="white" transform="translate(0 13)" />
        </clipPath>
      </defs>
    </svg>
  );
}
