export default function HomeIcon() {
  return (
    <svg width="35" height="33" viewBox="0 0 35 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.5833 29.5404C19.5089 29.5412 18.5592 29.5654 17.7368 29.6083C16.8291 29.5646 15.7288 29.5404 14.4167 29.5405V21.5833H20.5833V29.5404ZM12.1179 32.3333C14.109 32.3895 15.9888 32.3093 17.5179 32.1554C18.9054 32.2657 20.6891 32.3333 22.875 32.3333H28.25C29.5116 32.3333 30.5417 31.3032 30.5417 30.0417V18H33.0875C34.3534 18 34.9968 16.4197 34.0092 15.566L19.035 2.07849L19.035 2.07849L19.0338 2.07739C18.1632 1.29838 16.8368 1.29838 15.9662 2.07739L15.9662 2.07739L15.9649 2.07849L0.988343 15.5682C0.0405455 16.4076 0.623489 18 1.91249 18H4.45833V30.0417C4.45833 31.3032 5.48843 32.3333 6.74999 32.3333H12.1179Z"
        fill="currentColor"
        stroke="#0F0F0F"
      />
    </svg>
  );
}
