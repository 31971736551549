export default function LoogieIcon({ className }) {
  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 39 42"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.51279 13.519C9.51151 13.4596 9.51086 13.4001 9.51086 13.3404C9.51086 8.87513 13.1307 5.25531 17.596 5.25531C18.0492 5.25531 18.4936 5.29259 18.9265 5.36427C20.273 2.77132 22.9829 1 26.1064 1C30.5717 1 34.1915 4.61982 34.1915 9.0851C34.1915 11.4482 33.1778 13.5745 31.5614 15.0528C35.267 17.7783 37.5956 21.721 37.5956 26.1064C37.5956 28.5596 36.8669 30.8743 35.576 32.9149C32.5388 37.7162 26.3894 41 19.2977 41C9.1921 41 0.99987 34.3319 0.99987 26.1064C0.99987 20.8102 4.3962 16.1596 9.51279 13.519Z"
        fill="#9F5DF2"
      />
      <path
        d="M9.51279 13.519L9.97141 14.4076L10.5261 14.1213L10.5126 13.4973L9.51279 13.519ZM18.9265 5.36427L18.7632 6.35084L19.4794 6.46943L19.814 5.82512L18.9265 5.36427ZM31.5614 15.0528L30.8865 14.3149L29.9878 15.1368L30.9689 15.8584L31.5614 15.0528ZM35.576 32.9149L36.4212 33.4495L35.576 32.9149ZM8.51086 13.3404C8.51086 13.4073 8.51158 13.4741 8.51303 13.5406L10.5126 13.4973C10.5114 13.4451 10.5109 13.3928 10.5109 13.3404H8.51086ZM17.596 4.25531C12.5784 4.25531 8.51086 8.32284 8.51086 13.3404H10.5109C10.5109 9.42741 13.683 6.25531 17.596 6.25531V4.25531ZM19.0899 4.3777C18.6032 4.29712 18.1041 4.25531 17.596 4.25531V6.25531C17.9942 6.25531 18.3841 6.28806 18.7632 6.35084L19.0899 4.3777ZM26.1064 0C22.5951 0 19.5508 1.99226 18.039 4.90342L19.814 5.82512C20.9952 3.55037 23.3706 2 26.1064 2V0ZM35.1915 9.0851C35.1915 4.06754 31.124 0 26.1064 0V2C30.0194 2 33.1915 5.17211 33.1915 9.0851H35.1915ZM32.2363 15.7907C34.0511 14.1309 35.1915 11.7405 35.1915 9.0851H33.1915C33.1915 11.1559 32.3044 13.018 30.8865 14.3149L32.2363 15.7907ZM38.5956 26.1064C38.5956 21.3451 36.0652 17.124 32.1539 14.2472L30.9689 15.8584C34.4688 18.4326 36.5956 22.097 36.5956 26.1064H38.5956ZM36.4212 33.4495C37.8052 31.2616 38.5956 28.7633 38.5956 26.1064H36.5956C36.5956 28.3559 35.9286 30.487 34.7309 32.3803L36.4212 33.4495ZM19.2977 42C26.6786 42 33.1736 38.5832 36.4212 33.4495L34.7309 32.3803C31.9039 36.8493 26.1002 40 19.2977 40V42ZM-0.000129104 26.1064C-0.000129104 35.0667 8.84205 42 19.2977 42V40C9.54216 40 1.99987 33.5971 1.99987 26.1064H-0.000129104ZM9.05417 12.6303C3.68842 15.3996 -0.000129104 20.3507 -0.000129104 26.1064H1.99987C1.99987 21.2697 5.10398 16.9197 9.97141 14.4076L9.05417 12.6303Z"
        fill="black"
      />
      <circle r="7.5851" transform="matrix(-1 0 0 1 26.1063 9.0851)" fill="white" stroke="#0F0F0F" />
      <path
        d="M34.5991 33.4584C30.9121 35.1445 23.244 35.4685 20.1489 32.0638M35.209 32.5593C36.4185 30.6143 37.0957 28.4227 37.0957 26.1064C37.0957 18.2483 29.2284 11.7128 19.2978 11.7128C9.36724 11.7128 1.49998 18.2483 1.49998 26.1064C1.49998 33.9645 9.36724 40.5 19.2978 40.5C25.412 40.5 30.7745 38.0079 33.9713 34.2504C32.2932 34.849 30.1295 35.199 27.9798 35.1915C24.892 35.1807 21.6256 34.4315 19.7789 32.4002L20.5189 31.7275C22.0765 33.4408 24.9803 34.181 27.9833 34.1915C30.9224 34.2017 33.7573 33.5122 35.209 32.5593Z"
        fill="#9F5DF2"
        stroke="#0F0F0F"
      />
      <path
        d="M10.0114 13.3404C10.0114 17.5295 13.4074 20.9255 17.5965 20.9255C21.7857 20.9255 25.1816 17.5295 25.1816 13.3404C25.1816 9.15128 21.7857 5.75531 17.5965 5.75531C13.4074 5.75531 10.0114 9.15128 10.0114 13.3404Z"
        fill="white"
        stroke="#0F0F0F"
      />
      <ellipse rx="1.27659" ry="1.2766" transform="matrix(-1 0 0 1 18.4461 14.1915)" fill="#0F0F0F" />
      <ellipse rx="1.27659" ry="1.2766" transform="matrix(-1 0 0 1 27.8093 9.93614)" fill="#0F0F0F" />
    </svg>
  );
}
