export default function LogoIcon() {
  return (
    <svg width="134" height="56" viewBox="0 0 134 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle r="13.2557" transform="matrix(-1 0 0 1 43.7149 31.1313)" fill="white" stroke="#0F0F0F" />
      <circle r="13.2557" transform="matrix(-1 0 0 1 29.2351 38.3712)" fill="white" stroke="#0F0F0F" />
      <circle r="2.17195" transform="matrix(-1 0 0 1 30.6831 39.8191)" fill="#0F0F0F" />
      <circle r="2.17195" transform="matrix(-1 0 0 1 46.6108 32.5793)" fill="#0F0F0F" />
      <path d="M55 52C51.9154 54.0627 43.597 57.1192 35 52.8438" stroke="#0F0F0F" />
      <path
        d="M16.4214 7.96383C16.4214 9.68983 16.0782 11.1458 15.3917 12.3318C14.7053 13.5128 13.7789 14.4086 12.6125 15.0192C11.4461 15.6249 10.1455 15.9277 8.71069 15.9277C7.26558 15.9277 5.95982 15.6223 4.79341 15.0117C3.63216 14.396 2.70833 13.4976 2.0219 12.3167C1.34063 11.1307 1 9.67974 1 7.96383C1 6.23783 1.34063 4.78435 2.0219 3.60341C2.70833 2.41741 3.63216 1.52161 4.79341 0.915992C5.95982 0.305331 7.26558 0 8.71069 0C10.1455 0 11.4461 0.305331 12.6125 0.915992C13.7789 1.52161 14.7053 2.41741 15.3917 3.60341C16.0782 4.78435 16.4214 6.23783 16.4214 7.96383ZM11.9932 7.96383C11.9932 7.03522 11.8667 6.25297 11.6138 5.61707C11.3661 4.97613 10.9971 4.49164 10.5068 4.1636C10.0216 3.83051 9.42292 3.66397 8.71069 3.66397C7.99846 3.66397 7.39719 3.83051 6.90688 4.1636C6.42174 4.49164 6.05272 4.97613 5.79983 5.61707C5.55209 6.25297 5.42823 7.03522 5.42823 7.96383C5.42823 8.89244 5.55209 9.67721 5.79983 10.3182C6.05272 10.9541 6.42174 11.4385 6.90688 11.7716C7.39719 12.0997 7.99846 12.2637 8.71069 12.2637C9.42292 12.2637 10.0216 12.0997 10.5068 11.7716C10.9971 11.4385 11.3661 10.9541 11.6138 10.3182C11.8667 9.67721 11.9932 8.89244 11.9932 7.96383Z"
        fill="#0F0F0F"
      />
      <path
        d="M19.4129 15.7157C18.8606 15.7157 18.4129 15.268 18.4129 14.7157V1.21197C18.4129 0.659681 18.8606 0.211965 19.4129 0.211965H25.2565C26.4333 0.211965 27.4629 0.439071 28.3455 0.893282C29.228 1.34749 29.9144 1.98591 30.4047 2.80854C30.8951 3.63116 31.1402 4.59258 31.1402 5.69278C31.1402 6.80307 30.8873 7.76448 30.3815 8.57701C29.8809 9.38955 29.1764 10.0153 28.2681 10.4544C27.3649 10.8935 26.3094 11.113 25.1017 11.113H22.0141C21.4618 11.113 21.0141 10.6653 21.0141 10.113V8.84271C21.0141 8.29042 21.4618 7.84271 22.0141 7.84271H24.2346C24.7404 7.84271 25.1714 7.75691 25.5275 7.58532C25.8888 7.40868 26.1649 7.15887 26.3559 6.83587C26.552 6.51288 26.65 6.13185 26.65 5.69278C26.65 5.24866 26.552 4.87015 26.3559 4.55725C26.1649 4.2393 25.8888 3.99706 25.5275 3.83051C25.1714 3.65892 24.7404 3.57313 24.2346 3.57313H23.7173C23.165 3.57313 22.7173 4.02084 22.7173 4.57312V14.7157C22.7173 15.268 22.2696 15.7157 21.7173 15.7157H19.4129Z"
        fill="#0F0F0F"
      />
      <path
        d="M33.286 3.60341C32.7337 3.60341 32.286 3.15569 32.286 2.60341V1.21197C32.286 0.65968 32.7337 0.211965 33.286 0.211965H45.0661C45.6184 0.211965 46.0661 0.65968 46.0661 1.21197V2.60341C46.0661 3.15569 45.6184 3.60341 45.0661 3.60341H42.2973C41.745 3.60341 41.2973 4.05112 41.2973 4.60341V14.7157C41.2973 15.268 40.8495 15.7157 40.2973 15.7157H38.0548C37.5025 15.7157 37.0548 15.268 37.0548 14.7157V4.60341C37.0548 4.05112 36.6071 3.60341 36.0548 3.60341H33.286Z"
        fill="#0F0F0F"
      />
      <path
        d="M50.9904 0.211965C51.5427 0.211965 51.9904 0.65968 51.9904 1.21197V14.7157C51.9904 15.268 51.5427 15.7157 50.9904 15.7157H48.6861C48.1338 15.7157 47.6861 15.268 47.6861 14.7157V1.21197C47.6861 0.659681 48.1338 0.211965 48.6861 0.211965H50.9904Z"
        fill="#0F0F0F"
      />
      <path
        d="M54.0303 1.21197C54.0303 0.659681 54.4781 0.211965 55.0303 0.211965H58.7227C59.1255 0.211965 59.489 0.453611 59.6448 0.825015L63.0367 8.90771C63.0584 8.95961 63.1092 8.99338 63.1655 8.99338C63.2218 8.99338 63.2726 8.95961 63.2944 8.90771L66.6862 0.825016C66.842 0.453612 67.2055 0.211965 67.6083 0.211965H71.3007C71.8529 0.211965 72.3007 0.65968 72.3007 1.21197V14.7157C72.3007 15.268 71.8529 15.7157 71.3007 15.7157H69.0892C68.5369 15.7157 68.0892 15.268 68.0892 14.7157V6.82644C68.0892 6.78566 68.0561 6.7526 68.0154 6.7526C67.9851 6.7526 67.958 6.771 67.9468 6.79906L64.6863 14.9654C64.5346 15.3453 64.1668 15.5946 63.7576 15.5946H62.5751C62.1651 15.5946 61.7966 15.3443 61.6455 14.9632L58.3841 6.73861C58.373 6.7105 58.3458 6.69204 58.3156 6.69204C58.2748 6.69204 58.2418 6.72507 58.2418 6.7658V14.7157C58.2418 15.268 57.7941 15.7157 57.2418 15.7157H55.0303C54.4781 15.7157 54.0303 15.268 54.0303 14.7157V1.21197Z"
        fill="#0F0F0F"
      />
      <path
        d="M77.6449 0.211965C78.1972 0.211965 78.6449 0.65968 78.6449 1.21197V14.7157C78.6449 15.268 78.1972 15.7157 77.6449 15.7157H75.3406C74.7883 15.7157 74.3406 15.268 74.3406 14.7157V1.21197C74.3406 0.659681 74.7883 0.211965 75.3406 0.211965H77.6449Z"
        fill="#0F0F0F"
      />
      <path
        d="M90.1532 5.05688C89.6325 5.05688 89.2393 4.62136 88.944 4.19253C88.863 4.07487 88.7653 3.96934 88.651 3.87593C88.3104 3.59331 87.7917 3.452 87.095 3.452C86.6511 3.452 86.2872 3.50499 86.0034 3.61098C85.7247 3.71191 85.5182 3.8507 85.3841 4.02734C85.2499 4.20397 85.1802 4.40585 85.175 4.63295C85.1647 4.81968 85.1983 4.98875 85.2757 5.14015C85.3582 5.28651 85.4873 5.42025 85.6628 5.54137C85.8382 5.65745 86.0627 5.76343 86.3363 5.85932C86.6098 5.95521 86.935 6.041 87.3117 6.11671L88.6123 6.38923C89.4897 6.57092 90.2407 6.81064 90.8652 7.1084C91.4896 7.40616 92.0006 7.75691 92.398 8.16066C92.7954 8.55935 93.087 9.00852 93.2728 9.50815C93.4638 10.0078 93.5618 10.5528 93.567 11.1433C93.5618 12.1628 93.3012 13.0258 92.7851 13.7323C92.269 14.4389 91.5309 14.9763 90.571 15.3448C89.6162 15.7132 88.4678 15.8974 87.1259 15.8974C85.7479 15.8974 84.5454 15.698 83.5183 15.2993C82.4964 14.9006 81.7016 14.2875 81.1339 13.4598C80.7235 12.8522 80.4614 12.1211 80.3476 11.2665C80.2746 10.7175 80.7325 10.2652 81.2863 10.2652H83.4228C83.954 10.2652 84.3595 10.6996 84.5791 11.1833C84.6237 11.2814 84.6753 11.3741 84.7338 11.4613C84.9505 11.7842 85.255 12.029 85.6473 12.1956C86.0447 12.3621 86.5169 12.4454 87.064 12.4454C87.5233 12.4454 87.9078 12.3899 88.2175 12.2788C88.5272 12.1678 88.762 12.0139 88.922 11.8171C89.082 11.6202 89.1646 11.3956 89.1697 11.1433C89.1646 10.9061 89.0846 10.6992 88.9297 10.5226C88.7801 10.3409 88.5323 10.1794 88.1865 10.0381C87.8407 9.8917 87.3737 9.75544 86.7853 9.62927L85.206 9.29618C83.8022 8.99842 82.6951 8.50131 81.8848 7.80486C81.0797 7.10335 80.6797 6.14699 80.6849 4.93576C80.6797 3.95163 80.9481 3.09116 81.49 2.35433C82.0371 1.61245 82.7932 1.03459 83.7583 0.620755C84.7286 0.206918 85.8408 0 87.095 0C88.3749 0 89.482 0.209442 90.4161 0.628325C91.3503 1.04721 92.0703 1.63768 92.5761 2.39975C92.9116 2.89689 93.1381 3.44954 93.2555 4.05769C93.3606 4.60191 92.8994 5.05688 92.3451 5.05688H90.1532Z"
        fill="#0F0F0F"
      />
      <path
        d="M95.7708 3.60341C95.2185 3.60341 94.7708 3.15569 94.7708 2.60341V1.21197C94.7708 0.65968 95.2185 0.211965 95.7708 0.211965H107.551C108.103 0.211965 108.551 0.65968 108.551 1.21197V2.60341C108.551 3.15569 108.103 3.60341 107.551 3.60341H104.782C104.23 3.60341 103.782 4.05112 103.782 4.60341V14.7157C103.782 15.268 103.334 15.7157 102.782 15.7157H100.54C99.9874 15.7157 99.5397 15.268 99.5397 14.7157V4.60341C99.5397 4.05112 99.092 3.60341 98.5397 3.60341H95.7708Z"
        fill="#0F0F0F"
      />
      <path
        d="M113.475 0.211965C114.028 0.211965 114.475 0.65968 114.475 1.21197V14.7157C114.475 15.268 114.028 15.7157 113.475 15.7157H111.171C110.619 15.7157 110.171 15.268 110.171 14.7157V1.21197C110.171 0.659681 110.619 0.211965 111.171 0.211965H113.475Z"
        fill="#0F0F0F"
      />
      <path
        d="M131.152 4.95002C131.282 5.52124 130.814 6.02586 130.228 6.02586H127.867C127.349 6.02586 126.94 5.61579 126.737 5.13907C126.726 5.11395 126.715 5.08908 126.703 5.06445C126.574 4.77678 126.394 4.52949 126.161 4.32257C125.934 4.11061 125.658 3.94911 125.333 3.83808C125.008 3.72201 124.639 3.66397 124.226 3.66397C123.503 3.66397 122.892 3.83556 122.391 4.17874C121.896 4.52192 121.519 5.01398 121.261 5.65493C121.008 6.29587 120.881 7.0655 120.881 7.96383C120.881 8.91263 121.011 9.7075 121.269 10.3484C121.532 10.9843 121.911 11.4638 122.407 11.7868C122.902 12.1047 123.498 12.2637 124.195 12.2637C124.592 12.2637 124.948 12.2157 125.263 12.1199C125.578 12.0189 125.852 11.8751 126.084 11.6884C126.316 11.5016 126.505 11.277 126.649 11.0146C126.664 10.9878 126.678 10.9608 126.692 10.9333C126.918 10.4917 127.318 10.1163 127.814 10.1197L130.243 10.1366C130.823 10.1406 131.283 10.6411 131.12 11.1981C131.027 11.5126 130.905 11.83 130.752 12.1501C130.427 12.8264 129.965 13.4522 129.366 14.0275C128.773 14.5978 128.037 15.0571 127.16 15.4053C126.283 15.7535 125.263 15.9277 124.102 15.9277C122.647 15.9277 121.341 15.6223 120.185 15.0117C119.034 14.401 118.123 13.5027 117.452 12.3167C116.786 11.1307 116.453 9.67974 116.453 7.96383C116.453 6.23783 116.794 4.78435 117.475 3.60341C118.156 2.41741 119.075 1.52161 120.231 0.915992C121.387 0.305331 122.678 0 124.102 0C125.103 0 126.025 0.13374 126.866 0.401219C127.707 0.668699 128.445 1.05983 129.08 1.5746C129.715 2.08432 130.226 2.71265 130.613 3.45957C130.849 3.91589 131.029 4.4127 131.152 4.95002Z"
        fill="#0F0F0F"
      />
      <path
        d="M113.953 41.0802C116.027 36.9253 119.137 40.3738 119.137 40.3738C124.606 46.5988 129.506 38.9611 121.419 37.7396C113.331 36.5182 112.926 31.9267 113.047 29.5868C113.168 27.2469 114.448 22.4259 121.187 21.5077C127.927 20.5894 130.873 26.3837 131.503 29.3956C131.503 29.3956 131.71 30.9163 129.118 31.2695C126.526 31.6226 125.671 30.1903 125.671 30.1903C125.697 25.5399 119.576 25.5994 118.776 28.0318C116.909 33.7074 125.334 32.5596 127.381 33.055C129.429 33.5505 136.09 38.8385 128.598 46.8294C122.835 52.9762 111.904 45.1841 113.953 41.0802Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
      <path
        d="M80.743 48.3448C77.9309 39.9193 79.5713 33.132 80.743 30.7916C84.1177 29.588 86.836 30.2901 87.7734 30.7916C90.5855 39.2172 88.9451 46.0044 87.7734 48.3448C84.9612 49.9497 81.9147 49.0135 80.743 48.3448Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
      <path
        d="M80.743 28.3167C77.9309 25.0761 79.5713 22.4656 80.743 21.5654C82.9886 19.8403 86.2763 19.8403 87.7734 21.5654C90.5855 24.806 88.9451 27.4165 87.7734 28.3167C86.3733 29.3923 82.0862 29.8645 80.743 28.3167Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
      <path
        d="M78.1372 29.2722C75.9652 32.4407 71.6214 31.6486 71.6214 30.0643C71.6214 29.5363 70.1734 27.688 67.2774 27.688C62.8734 27.688 60.0376 35.6092 62.9335 39.5698C65.8295 43.5304 71.3799 41.9462 72.3452 39.5698H67.2774C66.5534 37.9856 65.8294 36.4013 67.2774 34.025H78.8612C80.0196 46.0652 73.552 49.0753 70.1734 49.0753C53.5217 51.4517 48.7893 25.9026 62.9336 21.351C75.2412 17.3904 77.6545 25.3116 78.1372 29.2722Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
      <path
        d="M110.408 22.7143C102.326 20.9127 95.0137 21.9636 92.3678 22.7143C89.4814 30.521 91.1651 42.9817 92.3678 48.2362C99.2953 50.0378 107.281 48.9869 110.408 48.2362C111.563 46.4347 110.889 43.4821 110.408 42.2311C106.944 41.03 101.268 41.7306 98.8623 42.2311C100.017 39.2285 99.3434 37.9774 98.8623 37.7272C102.903 38.9282 108.243 38.2276 110.408 37.7272C111.563 36.5262 110.889 33.7238 110.408 32.4727C105.357 30.9714 99.5839 32.4727 98.8623 32.4727C100.017 30.0706 98.8623 27.9688 98.8623 27.9688C101.027 28.7195 108.484 28.4692 110.408 27.9688C111.563 26.7678 110.889 23.9654 110.408 22.7143Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
      <path
        d="M2.70693 20.9475C0.390182 29.1636 1.74162 40.9986 2.70693 45.8891C6.18208 50.584 17.6693 48.8235 22.9785 47.3563C24.1368 45.0089 23.4611 41.4877 22.9785 40.0205C20.0825 37.8198 15.0146 37.8198 12.1187 40.0205C13.8563 35.3257 12.8427 25.349 12.1187 20.9475C8.64358 19.1869 4.39622 20.2139 2.70693 20.9475Z"
        fill="#A056FF"
        stroke="#0F0F0F"
      />
    </svg>
  );
}
