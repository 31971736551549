export default function CopyIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_155_3803)">
        <path
          d="M6 23C5.45 23 4.97933 22.8132 4.588 22.4397C4.196 22.0655 4 21.6159 4 21.0909V14.4091V7.97802C4 7.83954 4.11226 7.72727 4.25075 7.72727C4.38865 7.72727 4.50067 7.83862 4.50149 7.97652L4.588 22.4397L18.7621 22.5214C18.8937 22.5221 19 22.629 19 22.7607C19 22.8929 18.8929 23 18.7607 23H6ZM9 20.1818C8.45 20.1818 7.97933 19.995 7.588 19.6215C7.196 19.2473 7 18.7977 7 18.2727V4.90909C7 4.38409 7.196 3.9345 7.588 3.56032C7.97933 3.18677 8.45 3 9 3H16L22 8.72727V18.2727C22 18.7977 21.8043 19.2473 21.413 19.6215C21.021 19.995 20.55 20.1818 20 20.1818H9ZM15 9.68182H20L15 4.90909V9.68182Z"
          fill="#A056FF"
        />
        <path
          d="M4.588 22.4397C4.97933 22.8132 5.45 23 6 23H18.7607C18.8929 23 19 22.8929 19 22.7607V22.7607C19 22.629 18.8937 22.5221 18.7621 22.5214L4.588 22.4397ZM4.588 22.4397C4.196 22.0655 4 21.6159 4 21.0909V14.4091V7.97802C4 7.83954 4.11226 7.72727 4.25075 7.72727V7.72727C4.38865 7.72727 4.50067 7.83862 4.50149 7.97652L4.588 22.4397ZM9 20.1818C8.45 20.1818 7.97933 19.995 7.588 19.6215C7.196 19.2473 7 18.7977 7 18.2727V4.90909C7 4.38409 7.196 3.9345 7.588 3.56032C7.97933 3.18677 8.45 3 9 3H16L22 8.72727V18.2727C22 18.7977 21.8043 19.2473 21.413 19.6215C21.021 19.995 20.55 20.1818 20 20.1818H9ZM15 9.68182H20L15 4.90909V9.68182Z"
          stroke="black"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_155_3803">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
