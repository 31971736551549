export default function WalletIcon({ className, style }) {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      className={className}
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_47_21)">
        <path
          d="M21.889 10.1111V10.6111H22.389H23.7669C23.8327 10.6193 23.8937 10.6506 23.9387 10.6997C23.9869 10.7522 24.0132 10.8211 24.0123 10.8923L24.0123 10.8923V10.8983V18.1144C24.0115 18.1366 24.0063 18.1586 23.9971 18.1789C23.9875 18.2002 23.9736 18.2193 23.9563 18.235C23.939 18.2507 23.9187 18.2627 23.8966 18.2703C23.8745 18.2779 23.8511 18.281 23.8278 18.2792L23.809 18.2778H23.7901H22.389H21.889V18.7778V22.3889C21.889 22.4478 21.8656 22.5043 21.8239 22.546C21.7822 22.5877 21.7257 22.6111 21.6667 22.6111L5.16397 22.6111L5.16155 22.6111C4.835 22.6127 4.51135 22.5498 4.20913 22.4262C3.9069 22.3025 3.63205 22.1204 3.40031 21.8903C3.16856 21.6602 2.98448 21.3867 2.85861 21.0854C2.73281 20.7843 2.66761 20.4613 2.66675 20.1349V4.97427C2.67164 4.76269 2.71785 4.55409 2.8028 4.36022C2.88839 4.16491 3.01161 3.98838 3.16544 3.84071C3.31926 3.69304 3.50068 3.57712 3.69931 3.49957C3.89795 3.42203 4.10992 3.38438 4.32311 3.38877L4.32311 3.38888H4.33341H21.3779C21.4368 3.38888 21.4933 3.41229 21.535 3.45397C21.5767 3.49564 21.6001 3.55217 21.6001 3.6111C21.6001 3.67004 21.5767 3.72656 21.535 3.76824C21.4933 3.80991 21.4368 3.83332 21.3779 3.83332H4.33341C4.00926 3.83332 3.69838 3.96209 3.46917 4.1913C3.23996 4.42051 3.11119 4.73139 3.11119 5.05555C3.11119 5.3797 3.23996 5.69058 3.46917 5.91979C3.69838 6.149 4.00926 6.27777 4.33341 6.27777H21.6667C21.7257 6.27777 21.7822 6.30118 21.8239 6.34285C21.8656 6.38453 21.889 6.44105 21.889 6.49999V10.1111ZM23.1112 17.8333H23.6112V17.3333V11.5555V11.0555H23.1112H16.9145H16.9056L16.8967 11.0559C16.0242 11.0869 15.1996 11.4631 14.6042 12.1016C14.0126 12.736 13.6952 13.5779 13.7204 14.4444C13.6952 15.311 14.0126 16.1529 14.6042 16.7873C15.1996 17.4258 16.0242 17.8019 16.8967 17.833L16.9056 17.8333H16.9145H23.1112Z"
          fill="currentColor"
          stroke="black"
        />
        <path
          d="M17.3621 15.47C17.9605 15.47 18.4455 14.985 18.4455 14.3867C18.4455 13.7884 17.9605 13.3033 17.3621 13.3033C16.7638 13.3033 16.2788 13.7884 16.2788 14.3867C16.2788 14.985 16.7638 15.47 17.3621 15.47Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_47_21">
          <rect width="26" height="26" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
